import {
  ACCESIBILITY_STATEMENT_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  WHAT_IS_SUMA_URL,
  WHAT_WE_DO_URL,
} from '@/utils/constants';
import Image from "next/legacy/image";
import { getYear } from 'date-fns';
import logo from '@/images/logo-suma.svg';
import Typography from '@/components/atoms/@next/Typography';
import useContentfulFooter from '@/hooks/contenful/useContentfulFooter';

export const FOOTER_HEIGHT_CLASS = 'h-[53px] md:h-[5rem]';

export const Footer = () => {
  const page = useContentfulFooter();

  return (
    <footer className="bg-sumawealth-red-100">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="flex flex-1 flex-col justify-between md:flex-row">
            <div className="relative h-[32px] w-[65px] md:h-[48px] md:w-[98px]">
              <Image
                src={logo}
                layout="fill"
                objectFit="contain"
                alt="Suma logo"
                className="select-none"
              />
            </div>
            <div className="mt-10 md:mt-0">
              <a href={WHAT_WE_DO_URL} target="_blank" rel="noreferrer">
                <Typography
                  variant="heading"
                  size="24px"
                  className="!font-normal text-sumawealth-neutral-0"
                >
                  {page?.items.whatWeDo ? (
                    page?.items.whatWeDo.text
                  ) : (
                    <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
                  )}
                </Typography>
              </a>
            </div>
            <div className="mt-4 md:mt-0">
              <a href={WHAT_IS_SUMA_URL} target="_blank" rel="noreferrer">
                <Typography
                  variant="heading"
                  size="24px"
                  className="!font-normal text-sumawealth-neutral-0"
                >
                  {page?.items.aboutSuma ? (
                    page?.items.aboutSuma.text
                  ) : (
                    <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
                  )}
                </Typography>
              </a>
            </div>
            <div className="mt-4 md:mt-0">
              <a href={ACCESIBILITY_STATEMENT_URL} target="_blank" rel="noreferrer">
                <Typography
                  variant="heading"
                  size="24px"
                  className="!font-normal text-sumawealth-neutral-0"
                >
                  {page?.items.accessibilityStatement ? (
                    page?.items.accessibilityStatement.text
                  ) : (
                    <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
                  )}
                </Typography>
              </a>
            </div>
            <div className="mt-4 md:mt-0">
              <Typography
                variant="body"
                size="14px"
                className="!font-medium text-sumawealth-neutral-0"
              >
                {getYear(new Date())} Suma Inc.
              </Typography>
            </div>
            <div className="mt-4 md:mt-0">
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                <Typography
                  variant="body"
                  size="14px"
                  className="!font-medium text-sumawealth-neutral-0 underline"
                >
                  {page?.items.privacyPolicy ? (
                    page?.items.privacyPolicy.text
                  ) : (
                    <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
                  )}
                </Typography>
              </a>
            </div>
            <div className="mt-4 md:mt-0">
              <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">
                <Typography
                  variant="body"
                  size="14px"
                  className="!font-medium text-sumawealth-neutral-0 underline"
                >
                  {page?.items.termsOfService ? (
                    page?.items.termsOfService.text
                  ) : (
                    <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
                  )}
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
